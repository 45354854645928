<template>
  <section>
    <LandingPage />
    <QGISIntro />
    <QGISVerein />
    <Organisation />
  </section>
</template>

<script>
import LandingPage from '@/pages/sections/home/LandingPage';
import QGISIntro from '@/pages/sections/home/QGISIntro';
import QGISVerein from '@/pages/sections/home/QGISVerein';
import Organisation from '@/pages/sections/home/Organisation';

export default {
  name: 'Home',
  components: {
    LandingPage,
    QGISIntro,
    QGISVerein,
    Organisation
  },
};
</script>
