<template>
  <section id="organisation">
    <div class="organisation_content">
      <h3 class="h3-title text-center">
        Organisation
      </h3>
      <p class="small_text">
          Die QGIS Anwendergruppe Österreich ist ein Zusammenschluss von 
          QGIS Anwender*innen und Unterstützer*innen in Österreich. 
          Die QGIS Anwendergruppe ist ein Verein im Sinne des 
          österreichischen Vereinsgesetz 2002.
        </p>
      <p class="small_text">
         Die QGIS Anwendergruppe besteht aus: 
      </p>
      <ul>
        <li class="small_text">
          Natürliche Personen
        </li>
        <li class="small_text">
          Juristischen Personen <br>(Behörden, Firmen und Forschungseinrichtungen)
        </li>
        <li class="small_text">
          Ehrenmitgliedern
        </li>
      </ul>
       <p class="small_text">
         Die formalen Einheiten der QGIS Usergruppe sind:
      </p>
      <ul>
        <li class="small_text">
          Die Generalversammlung (1x pro Jahr)
        </li>
        <li class="small_text">
          Der Vorstand (Obmann/Obfrau, Obmann Stellvertreter*in, Schriftführer*in,
             Kassier*in sowie kooptierten Beisitzer*innen)
        </li>
        <li class="small_text">
           Die Rechnungsprüfer*innen
        </li>
      </ul>
      
      <div id="team">
        <!-- <div class="vorstand"> -->
          <!-- <div class="coremember">
            <h3 class="h3-title">Der aktuelle Vorstand besteht aus:</h3>  
            <v-card class="mr-4 pa-4" min-height="320">
              <v-simple-table >
                <template v-slot:default>
                  <thead>
                    <tr >
                      <th class="text-left text-subtitle-1 orange--text">
                        Funktion
                      </th>
                      <th class="text-left text-subtitle-1 orange--text">
                        Name
                      </th>
                      <th class="text-left text-subtitle-1 orange--text">
                        Firma
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="(team, i) in teams"
                      :key="i"

                    >
                      <td>
                        <strong>{{ team.role }} </strong>
                        <br>
                      
                      </td>
                      <td>
                      
                        {{ team.name }}
                      </td>
                      
                      <td>
                        <a :href="team.companyUrl" target="_blank">
                          {{ team.company }}
                        </a>
                      </td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </v-card>
          </div> -->

          <!-- <div class="boardmember">
            <h3 class="h3-title">Kooptierte Vorstandsmitglieder</h3>   
            <v-card class="ml-4 pa-4">
              <v-simple-table>
                <template v-slot:default>
                  <thead>
                      <tr>
                        <th class="text-left text-subtitle-1 orange--text">
                          Name
                        </th>
                        <th class="text-left text-subtitle-1 orange--text">
                          Firma
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        v-for="(member, i) in boardMembers"
                        :key="i"
                      >
                      <td>{{ member.role }}</td> 
                        <td>{{ member.name }}</td>
                        <td>
                        <a :href="member.companyUrl" target="_blank">
                          {{ member.company }}
                        </a>
                      </td>
                      </tr>
                    </tbody>
                </template>
              </v-simple-table>
            </v-card>
          </div> --> 
        <!-- </div> -->
          <!-- <div class="small_text my-8">
            Der Verein wird durch den Obmann <strong>Dr. Paul Stampfl</strong> vertreten.
            Er ist unter <a href="mailto:obmann@qgis.at">obmann@qgis.at</a>  erreichbar.
          </div> -->
          
          <div class="title_row">
            <h4 class="h4-title mr-4">Statuten der QGIS Anwendergruppe Österreich -> </h4>
            <a href="/Statuten_AUT_qgis.pdf">hier</a> 
          </div>

          <h3 class="h3-title">Vernetzung</h3>   
          <div class="small_text mb">
            Als offizielle QGIS-Anwendergruppe versteht sich die QGIS Anwendergruppe Österreich 
            als Teil der weltweiten QGIS-Gemeinschaft und ist als stimmberechtigtes Mitglied 
            in die Entscheidungen der QGIS.ORG Vereinigung involviert.
          </div>
      </div>
        
    </div>
  </section>
</template>
<script>
export default {
  name: 'Organisation',
  data: () => ({
    teams: [
      {
        role: 'Obmann',
        name: 'Paul Stampfl',
        company: 'Telesis GmbH',
        companyUrl: 'https://telesis.at/',
        description: '',
        email: 'info@qgis.at',
        // imgUrl: require('@/assets/team/paul.jpg')
      },
      {
        role: 'Stellvertreter Obmann',
        name: 'Peter Laubichler',
        company: 'spider-sol GmbH',
        companyUrl: 'http://www.hydro-sol.at/',
        description: '',
        email: '',
        // imgUrl: require('@/assets/team/person_male2.jpg')
      },
      {
        role: 'Kassier',
        name: 'Andreas Jeitler',
        company: 'Hydro Ingenieure GmbH',
        companyUrl: 'https://www.hydro-ing.at/',
        description: '',
        email: '',
        // imgUrl: require('@/assets/team/person_male1.jpg')
      },
      {
        role: 'Schriftführerin',
        name: 'Soyol Marksteiner',
        company: 'GISolutions e.U',
        companyUrl: 'https://gisolutions.at/',
        description: '',
        email: '',
        // imgUrl: require('@/assets/team/person_female1.jpg')
      }
    ],
    boardMembers: [
      {
        role: 'Kooptierte Mitglieder',
        name: 'Werner Macho',
        company: 'QGIS.ORG',
        companyUrl: 'https://qgis.org/',
        description: '',
        email: '',
        // imgUrl: require('@/assets/team/person_male1.jpg')
      },
      {
        role: 'Kooptierte Mitglieder',
        name: 'Christian Schilchegger',
        company: 'IT Schilchegger',
        companyUrl: 'https://it-schilchegger.selfip.com/',
        description: '',
        email: '',
        // imgUrl: require('@/assets/team/person_male1.jpg')
      },
      {
        role: 'Kooptierte Mitglieder',
        name: 'Ralf Sembritzki',
        company: 'Hansa Luftbild AG',
        companyUrl: 'https://www.hansaluftbild.de/',
        description: '',
        email: '',
        // imgUrl: require('@/assets/team/person_male1.jpg')
      },
      {
        role: 'Kooptierte Mitglieder',
        name: 'Franz Gusenbauer',
        company: 'igutech',
        companyUrl: 'https://www.igutech.at/',
        description: '',
        email: '',
        // imgUrl: require('@/assets/team/person_male1.jpg')
      },
      {
        role: 'Kooptierte Mitglieder',
        name: 'Heiko Kinzl',
        company: 'hydro-IT GmbH',
        companyUrl: 'http://www.hydro-it.com/',
        description: '',
        email: '',
        // imgUrl: require('@/assets/team/person_male1.jpg')
      },
    ]
  })
}
</script>

<style scoped>

  #organisation {
    min-height: 60vh;
    padding: 0 2rem;
  }
  .organisation_content p {
    padding: 0 8vw;
  }
 
  .organisation_content ul {
    padding: 0 10vw;
    margin-left: 5vw;
  }
   .organisation_content ul li {
    list-style: circle;
    margin-bottom: 0.5rem;
    padding: 0 1rem;
  }

  #team {
    padding-left: 8vw;
    padding-right: 8vw;
    padding-bottom: 2rem;
  }

  #team a {
    text-decoration: none;
    color: #387038;
  }

  #team a:hover {
    text-decoration: underline;
    font-weight: bold;
  }
  .vorstand {
    display: flex;
    flex-direction: row;
  }
  .coremember, .boardmember {
    flex: 1;
  }

  .title_row {
    display: flex;
    flex-direction: row;
    
  }

  .title_row a {
    border: solid 1px #387038;
    border-radius: 5px;
    padding: 5px;
    width: 10vw;
    display: flex;
    justify-content: center;
    z-index: 2;
  }

  .title_row a:hover {
    color: #fff !important;
    background-color: #387038;
    text-decoration: none !important;
  }

  .small_text {
    margin-bottom: 0.25rem;
  }

  /* Smartphones */
  @media only screen and (max-width: 600px) {
    #organisation {
      padding: 0;
    }
  }

</style>