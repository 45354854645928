<template>
  <section id="qgis_intro">
    <h2 class="h2-title text-center">
      Ein freies Geographisches Informationssystem
    </h2>
    <div class="small_text">
      QGIS ist ein freies und benutzerfreundliches Geoinformationssystem zum
      Betrachten, Bearbeiten, Erfassen und Analysieren von raumbezogenen Daten
      und Information, das unter der
      <strong>GNU General Public License</strong> lizenziert ist. QGIS ist nicht
      nur ein Desktop-GIS. QGIS ist Kern einer modernen Geodateninfrastruktur
      (GDI) mit räumlichem Dateibrowser, Server, flexibel anwendbaren Plugins
      zur Funktionserweiterung und mehreren zur Auswahl stehenden
      WebGIS-Anwendungen.
    </div>
    <div class="d-flex flex-wrap justify-space-between qgis-content">
      <v-card
        elevation="4"
        class="mx-auto qgis-card"
        width="400"
        height="300"
        v-for="(item, i) in items"
        :key="i"
      >
        <v-icon color="#408240" size="50">
          {{ item.icon }}
        </v-icon>
        <v-card-title>{{ item.title }}</v-card-title>
        <v-card-subtitle>{{ item.subtitle }}</v-card-subtitle>
      </v-card>
    </div>
  </section>
</template>

<script>
export default {
  name: "QGIS-Intro",
  data: () => ({
    items: [
      {
        icon: "desktop_windows",
        title: "Betriebssysteme",
        subtitle:
          "QGIS läuft unter Linux, Unix, Mac OSX, Windows und Android und unterstützt eine Vielzahl an Vektor-, Raster- und Datenformaten, -funktionen und Datenbanken, wie PostGIS/PostgreSQL und SpatiaLite.",
      },
      {
        icon: "settings",
        title: "Datenverarbeitung",
        subtitle:
          "Wesentliche Merkmale der QGIS Umgebung sind die breite Unterstützung zum Erfassen, Analysieren, und Transformieren von Geodaten sowie eine Druckzusammenstellung zum Erstellen von qualitativ hochwertigen Karten.",
      },
      {
        icon: "api",
        title: "Geodateninfrastruktur - API",
        subtitle:
          "QGIS bietet eine offene Schnittstellenarchitektur, die das Anwenden von QGIS-Funktionalitäten in anderen Programmen oder das Schreiben von Plugins zur Funktionserweiterung erlaubt.",
      },
      {
        icon: "web",
        title: "Visulisierungsbibliothek",
        subtitle:
          "Der QGIS Server ermöglicht die Veröffentlichung von QGIS-Projekten und Layern als OGC kompatible WMS-, WFS- und WCS-Dienste. Durch Benutzung derselben Visulisierungsbibliothek lassen sich Desktop Layer, Attribute und Layouts 1:1 im Internet abbilden.",
      },
      {
        icon: "public",
        title: "QGIS-Webclienten",
        subtitle:
          "Die Anwender profitieren durch das Angebot unterschiedlicher WebClienten, modernen Symbolisierungs-, Beschriftungs- und Überblendungsfunktionen und umfangreichen kartographischen Designmöglichkeiten.",
      },
      {
        icon: "devices_other",
        title: "QGIS auf Handy und Tablet",
        subtitle:
          "Die QGIS-Geodateninfrastruktur ist nicht auf die Desktop Anwendungen beschränkt. Mobile und Touch-optimierte Apps ermöglichen die ortsunabhängige Erfassung von Felddaten und das Aktualisieren von Geodatenbanken in Echtzeit.",
      },
    ],
  }),
  methods: {
    // myFunction() {
    //  console.log("maybe open new blank tab...");
    // },
  },
};
</script>

<style scoped>
#qgis_intro {
  background-color: #f4f4f4;
  padding: 1.5rem;
}

.small_text {
  padding: 0 8vw;
}

.qgis-content {
  padding: 0 8vw;
  display: flex;
}

.qgis-card {
  margin: 2rem 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.v-card__subtitle {
  text-align: justify;
  padding: 1rem 1.55rem;
}

.v-icon {
  padding-top: 1rem;
}

/* Smartphones */
@media only screen and (max-width: 600px) {
  #qgis_intro {
    padding: 0;
  }
  .qgis-content {
    padding: 0;
  }
}
</style>