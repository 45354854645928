<template>
    <section id="qgis_verein">
      <div class="zweck_content">
        <h3 class="h3-title text-center">
          Vereinszweck
        </h3>
        <p class="small_text">
          Ziel des Vereins ist die Unterstützung und Förderung des QGIS-Projektes 
          und der QGIS-Anwender*innen um der Allgemeinheit einen Zugang zur 
          Verarbeitung räumlicher Daten durch die Verbreitung des freien <strong>
          Geographischen Informationssystems QGIS - 
              im Sinne von Freier Software 
          für Geoinformationssysteme - </strong> zu ermöglichen.  
        </p>

      
        <h3 class="h3-title text-center">
          Aktivitäten
        </h3>
        <ul>
          <li class="small_text">
            Förderung der Bildung, des Meinungsaustauschs und der Zusammenarbeit von QGIS-Anwendern, Entwicklern und Forschern. 
          </li>
          <li class="small_text">
            Koordinierung von QGIS Entwicklungen und verfügbaren finanziellen Ressourcen für Weiterentwicklungen der QGIS-Software, die im Interesse der österreichischen QGIS-Anwender*innen liegen. 
          </li>
          <li class="small_text">
            Öffentlichkeitsarbeit für das QGIS-Projekt innerhalb Österreichs, z.B. durch Informationsveranstaltungen, Bewerbungen, Fallstudien und Artikel in Fachzeitschriften. 
          </li>
          <li class="small_text">
            Die Information der Mitglieder über aktuelle Entwicklungen und Initiativen im QGIS-Umfeld. 
          </li>
          <li class="small_text">
            Unterstützung von Studien oder Forschungsarbeiten im Umfeld von QGIS und Freier Software für Geoinformationssysteme 
          </li>
          <li class="small_text">
            Sponsoring des allgemeinen QGIS-Projektes (www.qgis.org) und der weltweiten QGIS-Gemeinschaft. 
          </li>
        </ul>
      </div>
    </section>
</template>

<script>
export default { 
  name: 'QGISVerein'
}
</script>

<style scoped>
  #qgis_verein {
    padding: 0 2rem;
  }

  .zweck_content p {
    padding: 0 8vw;
  }

  .zweck_content ul {
    padding: 0 10vw;
  }
  .zweck_content ul li {
    list-style: circle;
    margin-bottom: 0.5rem;
    padding: 0 1rem;
  }
   /* Smartphones */
  @media only screen and (max-width: 600px) {
    #qgis_verein {
      padding: 0;
    }
  }
</style>