<template>
  <section id="becomeMemeber">
    <div>
      <!-- <div id="header_bg" style="color: #333; height:64px"></div> -->
      <h2 class="h2-title">Mitglied werden</h2>
      <p>
        Bitte senden Sie uns die ausgefüllte
        <a href="/Beitrittserklaerung.pdf" target="_blank"
          >Beitrittserklärung</a
        >
        per Brief oder per Email an
        <a href="mailto:info@qgis.at">info@qgis.at</a> zu.
      </p>
    </div>
  </section>
</template>

<script>
export default {
  name: 'BecomeMember',
  // mounted () {
  //   const getElement = document.getElementById('header_bg');
  //   getElement.style.color = '#333';
  //   getElement.style.height = 64;

  //   console.log(getElement);
  // }
};
</script>

<style scoped>
#becomeMemeber {
  height: 80vh;
  display: flex;
  flex-direction: column;
  padding: 4.5rem 4rem 3rem 3rem;
}
</style>
