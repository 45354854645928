<template>
  <section 
    id="news" 
    
    class="pt-16">
    <h3 class="h3-title text-center">
      Aktuelle Neuigkeiten via Twitter
    </h3>

    <v-card>
      <a 
        class="twitter-timeline" 
        href="https://twitter.com/QGISAT?ref_src=twsrc%5Etfw">
        Tweets by QGIS Österreich
      </a>
    </v-card>

  </section>
</template>

<script>


export default {
  name: 'News',

  data: () => ({
    loading: false,
  

  }),
  mounted() {
    if (localStorage.getItem('reloaded')) {
        // The page was just reloaded. Clear the value from local storage
        // so that it will reload the next time this page is visited.
        localStorage.removeItem('reloaded');
    } else {
        // Set a flag so that we know not to reload the page twice.
        localStorage.setItem('reloaded', '1');
        location.reload();
    }
  },
  methods: {
    // reserve () {
    //   this.loading = true
    //   setTimeout(() => (this.loading = false), 2000)
    // },
    // LoadOnce() { 
    //   window.location.reload(); 
    //   } 

  },

}
</script>

<style scoped>
  #news {
    min-height: 60vh;
    padding: 0 8vw;
  }

  .timeline-Tweet {
    margin: 0 1rem;
  }


  /* Smartphones */
  @media only screen and (max-width: 600px) {
    #scope {
      padding: 0;
    }
  }

</style>