<template>
  <section id="scope" class="pt-16">
    <h3 class="h3-title text-center">Arbeitsgruppen</h3>
    <div class="small_text ">
      Im Verein haben sich mehrere Arbeitsgruppen zu unterschiedlichen Fachthemen gebildet. 
      Wer Interesse hat mitzuarbeiten ist herzlich eingeladen sich bei der jeweilig 
      zuständigen Person zu melden.
    </div>
    <div class="scope_content">
      <v-card
        class="mx-auto my-12"
        max-width="360"
        :loading="loading"
        v-for="(item, i) in items"
        :key="i"
      >
        <template slot="progress">
          <v-progress-linear
            color="deep-purple"
            height="10"
            indeterminate
          ></v-progress-linear>
        </template>

        <v-img
          height="250"
          :src="item.imgUrl"
        ></v-img>
        <v-card-title class="text-wrap">
          {{ item.title }}
        </v-card-title>
        <v-card-text>
          {{ item.description }}
        </v-card-text>
        <v-divider class="mx-4"></v-divider>
        <v-card-subtitle>Zuständige Personen</v-card-subtitle>

        <v-card-text>
          <v-chip-group
            active-class="orange accent-4 white--text"
            column>
            <v-chip
              v-for="(contact, i) in item.contactPerson"
              :key="i"
              outlined>
              <a :href="contact.email">
                {{ contact.user }}
              </a>
            </v-chip>
          </v-chip-group>
        </v-card-text>
      </v-card>
    </div>
  </section>
</template>

<script>


export default {
  name: 'Scopes',

  data: () => ({
    loading: false,
   
    items: [
      {
        id: 'workshops',
        title: 'GIS Schulung und Ausbildung',
        description: 'Vermittlung von Wissen und Aufbau von Schulungsformaten und Lerninhalten zur QGIS Anwendung für Vereinsmitglieder und die interessierte Öffentlichkeit.  Das Themenspektrum reicht von Konzepten zur Beratung, Organisation und Unterstützung freier online Kurse und Webinaren bis hin zu professioneller Dienstleistung und akademischer Ausbildung.',
        contactPerson: [{
          user: 'Paul Stampfl',
          email: 'mailto:obmann@qgis.at',
        }],
        
        imgUrl: require('@/assets/img/workshops.jpg'),
      },
      {
        id: 'hydrology',
        title: 'Hydrologische und hydraulische Modellierung',
        description: 'Umgang mit Netzerstellung und Bearbeitung, Einlesen von Berechnungsergebnissen und deren Darstellung im Bereich der technischen Infrastrukturen, wie z.B. der Gefahren- und Schutzzonen im Wasserbau oder im Bereich der Wildbach und Lawinenverbauung. QGIS bietet zahlreiche Funktionalitäten und Anwendergruppen, die aktive an der Integration und Weiterentwicklung der Modellierungsumgebung arbeiten.',
        contactPerson: [{
          user: 'Werner Macho',
          email: 'mailto:werner.macho@gmail.com ',
        }],
        imgUrl: require('@/assets/img/hydrology.jpg'),
      },
      {
        id: 'webapplication',
        title: 'Webclient und Webanwendungen',
        description: 'Fragen und Interesse an der Entwicklung von Web-Anwendungen und Cloud Lösungen? Wir wollen Entwicklungen initiieren, Know-How aufbauen und Schulungen organisieren.',
        contactPerson: [
          {
            user:'Franz Gusenbauer',
            email: 'mailto:franz.gusenbauer@igutech.com'
          },
          {
            user: 'Soyol Marksteiner',
            email: 'mailto:soyol.marksteiner@gmail.com',
          }
        ],
        imgUrl: require('@/assets/img/webdevelopment.jpg'),
      },
      // {
      //   id: 'support',
      //   title: 'Fachliche Unterstützung und Betreuung',
      //   description: 'Sie planen QGIS in Ihrem Unternehmen oder Organisation einzusetzen, aber machen sich Sorgen, dass es keine Unterstützung und Serviceleistungen gibt? Hier entsteht eine alphabetische, in Kategorien aufgeteilte Liste von professionellen Dienstleistern.',
      //   contactPerson: [
      //     {
      //       user:'QGIS Verein',
      //       email: 'mailto:info@qgis.com'
      //     }
      //   ],
      //   imgUrl: require('@/assets/img/webdevelopment.jpg'),
      // }
    ]
  }),
   methods: {
    reserve () {
      this.loading = true
      setTimeout(() => (this.loading = false), 2000)
    },
  },

}
</script>

<style scoped>
  #scope {
    min-height: 60vh;
    padding: 0 8vw;
  }
  .scope_content {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
  }
  .small_text {
    padding: 0 8vw;
  }
  .v-chip__content a {
    color: #333;
    text-decoration: none;
  }
  .v-card__title {
    font-size: 1.2rem;
    word-break: break-word;
  }
  .v-card__text {
    text-align: justify;
  }

  /* Smartphones */
  @media only screen and (max-width: 600px) {
    #scope {
      padding: 0;
    }
  }

</style>