<template>
  <v-app id="app">
    <!-- App Header -->
     <v-app-bar id="app_header" fixed flat dark :color="bg" >
      <router-link :to="{ path: 'home', hash: '#landingpage' }" class="logo_content">
        <v-img
            class="mx-2"
            :src="require('@/assets/logos/qgisat_new.png')"
            max-height="65"
            max-width="65"
            contain
          ></v-img>

        <v-list-item two-line>
          <v-list-item-content>
            <v-list-item-title>QGIS AT</v-list-item-title>
            <v-list-item-subtitle>Anwendergruppe Österreich</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </router-link>

      <v-spacer></v-spacer>
      <v-btn text class="header_btn">
        <router-link :to="{name: 'home', hash: '#qgis_verein'}">
          Verein
        </router-link>
      </v-btn>

      <v-btn text class="header_btn">
        <router-link to="/news">
          Neuigkeiten
        </router-link>
      </v-btn>

      <v-btn text class="header_btn">
        <router-link to="/arbeitsgruppen">
          Arbeitsgruppen
        </router-link>
      </v-btn>

      <v-btn text class="header_btn">
        <router-link to="/become_member">
          Mitglied werden
        </router-link>
      </v-btn>
      
      <!-- TODO: Coming soon... @Soyol -->
      <!-- <v-btn text>
        <router-link to="/meetups">
          Anwendertreffen
        </router-link>
      </v-btn> -->



      <!-- Toggle Navigation Bar -->
        <v-app-bar-nav-icon @click="drawer = !drawer"></v-app-bar-nav-icon>
      </v-app-bar>

      <!-- Navigation Bar Menu -->
      <v-navigation-drawer
        v-model="drawer"
        right
        fixed
        transitionend
      >
        <v-list nav>
          <v-list-item-group active-class="text text--accent-4">
            <div style="height:250px;"></div>
            <v-list-item>
              <v-list-item-title>
                <router-link :to="{name: 'home', hash: '#landingpage'}">
                  Verein
                </router-link>
              </v-list-item-title>
            </v-list-item>

            <v-list-item>
              <v-list-item-title>
                <router-link to="/news">
                  Neuigkeiten
                </router-link>
              </v-list-item-title>
            </v-list-item>

            <v-list-item>
              <v-list-item-title>
                <router-link to="/arbeitsgruppen">
                  Arbeitsgruppen
                </router-link>
              </v-list-item-title>
            </v-list-item>

            <v-list-item>
              <v-list-item-title>
                <router-link to="/become_member">
                  Mitglied werden
                </router-link>
              </v-list-item-title>
            </v-list-item>

            <v-list-item>
              <v-list-item-title>
                <router-link :to="{name: 'privacy'}">
                Datenschutzerklärung
                </router-link>
                </v-list-item-title>
            </v-list-item>

            <!-- <v-list-item>
              <v-list-item-title>
                <router-link :to="{name: 'impressum'}">
                Impressum
                </router-link>
                </v-list-item-title>
            </v-list-item> -->

          </v-list-item-group>
        </v-list>
      </v-navigation-drawer>
   
    <router-view />
    <AppFooter />
    <footer class="footer_cookie">
      <cookie-law
        theme="base"
        buttonText="Zustimmen"
        buttonLink="/privacy"
        buttonLinkText="Datenschutzrichtlinien"
        buttonLinkNewTab:TRUE
        message="Diese Website verwendet Cookies, um dir das bestmögliche Erlebnis zu gewährleisten. Magst du weiterhin auf unserer Seite surfen, stimmst du unserer Cookie-Nutzung und unserer Datenschutzrichtlinie zu."
        v-on:accept="enableAnalyticsPlugin()"
      ></cookie-law>
    </footer>
  </v-app>
</template>

<script>
// import AppHeader from '@/main/Header';
import AppFooter from '@/main/Footer';
// import Sidebar from '@/main/Sidebar';
import CookieLaw from 'vue-cookie-law';
import { bootstrap } from 'vue-gtag';

export default {
  name: 'App',
  components: {
    // AppHeader,
    AppFooter,
    // Sidebar,
    CookieLaw,
  },
  data: () => ({
    drawer: false,
    bg : ''
  }),
  mounted () {
    window.onscroll = () => {
      this.changeColor();
    };
  },
  methods: {
    enableAnalyticsPlugin() {
      bootstrap().then(() => {});
    },
    changeColor () {
      const currentScrollPosition = window.pageYOffset || document.documentElement.scrollTop;
      if (currentScrollPosition > 150) {
        this.bg = 'rgba(33, 33, 33, 0.6)';
      } else if (this.$route.name !== 'home') {
        this.bg = 'rgba(33, 33, 33, 0.6)';
      } else {
         this.bg = 'transparent';
      }
    },
    toRouterLinkObjekt(item) {
      return {
        name: item.routername,
        hash: item.routerhash,
      };
    },
  },
  metaInfo: {
    // Children can override the title.
    title: 'QGIS Österreich',
    // Result: My Page Title ← My Site
    // If a child changes the title to "My Other Page Title",
    meta: [
      { 'http-equiv': 'Content-Type', content: 'text/html; charset=utf-8' },
      { name: 'viewport', content: 'width=device-width, initial-scale=1' },
      {
        name: 'description',
        content:
          'QGIS Anwendergruppe &#214;sterreich',
      },
      // OpenGraph data (Most widely used)
      { property: 'og:title', content: 'QGIS Österreich' },
      { property: 'og:site_name', content: 'QGIS Österreich' },
      { property: 'og:locale', content: 'de_DE' },
      // The list of types is available here: http://ogp.me/#types
      { property: 'og:type', content: 'website' },
      // Should the the same as your canonical link, see below.
      { property: 'og:url', content: 'https://www.qgis.at/' },

      // Itemprop
      { itemprop: 'addressLocality', content: 'Unterlinden' },
      { itemprop: 'addressRegion', content: 'Wolfurt' },
      { itemprop: 'addressCountry', content: 'Vorarlberg' },
      { itemprop: 'postalCode', content: '6922' },
      { itemprop: 'name', content: 'GISolutions' },
      { itemprop: 'telephone', content: '+43 660 4852504' },
      { itemprop: 'email', content: 'info@qgis.at' },
    ],
    link: [{ rel: 'canonical', href: 'https://www.qgis.at/index.html' }],
  }
}
</script>

<style>
/*
  QGIS Colors:
  light - #51a351
  dark - #387038
  button - #5bb75b
*/
* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}
body {
  font-family: Roboto, sans-serif !important;
  line-height: 1.4;
}
.text-center {
  text-align: center;
  font-size: 1.75rem;
}
.h2-title {
  color: #333;
  padding: 2rem 0;
  font-weight: 500;
  font-size: 2rem;
}
.h3-title {
  color: #387038;
  padding: 1.25rem 0;
  font-weight: 500;
  font-size: 1.4rem;
}
.h4-title {
  color: #333;
  font-weight: 500;
  font-size: 1.2rem;
}
.small_text {
  color: #333;
  line-height: 1.75rem;
  font-size: 1.15rem;
  text-align: justify;
  margin-bottom: 0.25rem;
}

button {
  text-transform: none !important;
}

/*  Cookie--bottom Cookie--qgis_at */
.Cookie--base {
  background-color: rgb(211, 211, 211);
  padding: 3.5rem;
  display: flex;
  flex-direction: column;
}
.Cookie__buttons a {
  text-decoration: none;
}
.v-app-bar__nav-icon {
  display: none;
}
.v-toolbar__content a {
  color: #fff !important;
  text-decoration: none;
  font-size: 0.8rem;
  font-weight: 400;
}
.v-toolbar__content a:hover {
  border-bottom: #387038 0.25px solid;
} 
.logo_content {
  display: flex;
  flex-direction: row;
}
.v-app-bar__nav-icon {
  display: none !important;
}


  /* Tablet */
  @media only screen and (min-width: 600px) and (max-width: 1024px) {
    .l-heading {
      font-size: 3.25rem !important;
    }
    .lead {
      font-size: 1.4rem !important;
    }
    .h2-title {
      font-size: 1.3rem !important;
    }
    .h3-title {
      font-size: 1.15rem !important;
    }
    .h4-title {
      font-size: 1rem !important;
    }
    .small_text {
      font-size: 1rem !important;
      
    }
  }

  /* Smartphones */
  @media only screen and (max-width: 600px) {
     .header_btn {
       display: none !important;
     }

    .v-app-bar__nav-icon {
      display: block !important;
    }
  
    .v-navigation-drawer__content a {
      color: #387038 !important;
      text-decoration: none;
      font-size: 1rem;
      font-weight: 400;
    }
    .l-heading {
      font-size: 2rem !important;
    }
    .lead {
      font-size: 1rem !important;
    }
    .h2-title {
      font-size: 1.3rem !important;
    }
    .h3-title {
      font-size: 1.15rem !important;
    }
    .h4-title {
      font-size: 1rem !important;
    }
    .small_text {
      font-size: 1rem !important;
    }
  
 
  }


</style>
