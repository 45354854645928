import Vue from 'vue'
import App from './App.vue'
import Vuetify from 'vuetify';
import vuetify from './plugins/vuetify';
import 'vuetify/dist/vuetify.min.css';
import VueRouter from 'vue-router';
import { routes } from './routes';

[Vuetify, VueRouter].forEach((x) => Vue.use(x));

const router = new VueRouter({
  routes,
  mode: 'history',
  // scrollBehavior(to, from, savedPosition) {
  scrollBehavior(to ) {
    if (to.hash) {
      return {
        selector: to.hash,
        behavior: 'smooth'
      }
    }
    return { x: 0, y: 0 }
  },
});

Vue.config.productionTip = false


new Vue({
  router,
  vuetify,
  render: h => h(App),
}).$mount('#app')
